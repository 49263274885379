import React from 'react';

import { continueExistingSessionReplay } from '@zola-helpers/client/dist/es/util/mixpanel/sessionReplay';
import { enhancedContext } from '@zola/tracking-contracts/src/plugins/enhancedContext';
import { enhancedTraits } from '@zola/tracking-contracts/src/plugins/enhancedTraits';
import { mixpanelContext } from '@zola/tracking-contracts/src/plugins/mixpanelContext';
import { useEffectOnce } from '@zola/zola-ui/src/hooks';

import { AppProps } from 'next/app';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import '../src/stylesheets/core.less';

type CustomAppProps = AppProps & {
  err?: unknown;
};

// Initialize Segment before any other rendering
if (typeof window !== 'undefined' && window.analytics) {
  continueExistingSessionReplay();
  window.analytics.register(enhancedContext, enhancedTraits(), mixpanelContext());
}

const CustomApp = ({ Component, pageProps, err }: CustomAppProps) => {
  useEffectOnce(() => {
    const logRocketActive = false;
    if (logRocketActive) {
      LogRocket.init('cl9xsz/zola-development');
      setupLogRocketReact(LogRocket);
    }
  });

  return <Component {...pageProps} err={err} />;
};

export default CustomApp;
